/* BELY */
@font-face {
  font-family: 'Bely';
  src:
    url('./fonts/bely/BelyDisplay-Regular.woff') format('woff'),
    url('./fonts/bely/BelyDisplay-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

/* TTNORMS */
@font-face {
  font-family: 'TTNorms';
  src:
    url('./fonts/ttnorms/TTNorms-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms';
  src:
    url('./fonts/ttnorms/TTNorms-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms';
  src:
    url('./fonts/ttnorms/TTNorms-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'TTNorms';
  src:
    url('./fonts/ttnorms/TTNorms-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms';
  src:
    url('./fonts/ttnorms/TTNorms-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/* MUSEO */
@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-100.woff') format('woff'),
    url('./fonts/museo/MuseoSans-100.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-100Italic.woff') format('woff'),
    url('./fonts/museo/MuseoSans-100Italic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-300.woff') format('woff'),
    url('./fonts/museo/MuseoSans-300.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-300Italic.woff') format('woff'),
    url('./fonts/museo/MuseoSans-300Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-500.woff') format('woff'),
    url('./fonts/museo/MuseoSans-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-500Italic.woff') format('woff'),
    url('./fonts/museo/MuseoSans-500Italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-700.woff') format('woff'),
    url('./fonts/museo/MuseoSans-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-700Italic.woff') format('woff'),
    url('./fonts/museo/MuseoSans-700Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-900.woff') format('woff'),
    url('./fonts/museo/MuseoSans-900.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Museo';
  src:
    url('./fonts/museo/MuseoSans-900Italic.woff') format('woff'),
    url('./fonts/museo/MuseoSans-900Italic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}